import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import HeroImage from "../images/case-study-banner.jpg";
import ClientImage from "../images/client-img.jpg";
import CaseStudyCta from "../images/case-study-cta.png";
import CaseStudyCtaImg from "../images/case-study-cta-img.png";
import LeaderIcon from "../assets/icons/leader-icon.svg";
import ShoppingIcon from "../assets/icons/shopping-icon.svg";
import ProtectionIcon from "../assets/icons/protection-icon.svg";
import CodingIcon from "../assets/icons/coding-icon.svg";
import JourneyIcon from "../assets/icons/journey-icon.svg";
import ContentModelIcon from "../assets/icons/content-model-icon.svg";
import SearchcontentIcon from "../assets/icons/search-content-icon.svg"; 


const CaseStudyPage = () => {
    return (
        <div className="template-case-study header-position">
            <Layout>
                <div className="hero-banner mb-104">
                    <div className="container"> 
                        <div className="hero-banner-wrap">
                            <div className="left-block">
                                <div className="banner-text">
                                    <div className="category-listing">
                                        <span class="extra-small-text font-light-color font-capital">retail</span>
                                        <span class="extra-small-text font-light-color font-capital">digital experience</span>
                                        <span class="extra-small-text font-light-color font-capital">CMS/Product discovery/journeys </span>
                                    </div>
                                    <h2>Delivering <br></br>Omnichannel Luxury Shopping Experience</h2>
                                    <p className="mb-40">How we built consistent customer experiences by implementing the Digital Experience Platform in record time?</p>
                                    <ul class="highlighted-title-text d-flex">
                                        <li className="font-light-color font-medium font-capital"><h2 className="font-medium">25%</h2> INCREASE IN <br></br>CONVERSION RATE</li>
                                        <li className="font-light-color font-medium font-capital"><h2 className="font-medium">16%</h2>INCREASE IN AVERAGE <br></br>SESSION DURATION</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="right-block">
                                <img loading="lazy" src={HeroImage} title="Delivering Omnichannel Luxury Shopping Experience" alt="Delivering Omnichannel Luxury Shopping Experience" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="image-title-section mb-208">
                    <div className="container-medium">
                        <div className="image-title-inner custom-row d-flex flex-wrap row-2 align-v-center">
                            <div className="title-block grid">
                                <h2 className="mb-24">The Client</h2>
                                <p>One of the largest U.S.-based high-end jewelry retailers with 60 years of experience in the business. With thousands of active customers and millions of orders from eCommerce and retail stores across 13 states.</p>
                            </div>
                            <div className="image-block grid">
                                <img loading="lazy" src={ClientImage} title="The Client" alt="The Client" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="full-content-section mb-80">
                    <div className="container-small">
                        <div className="full-content-inner">
                            <h2 className="mb-24">The Challenges</h2>
                            <p>The Client embarked on a digital transformation journey with an aim to increase customer loyalty, engagement, and sales by providing a hyper-personalized shopping experience to its customers across retail stores and an eCommerce website.</p>
                            <p>The Client reached out to us with a problem statement that reads, “Our company needs digital solutions that could unify disconnected customer data across 20+ different systems, generate customer segments to automatically enrich customer profiles to personalized every customer touchpoint throughout the entire customer journey, this is a major part of our multi-year digital strategy initiative.” </p>
                            <p>The Client wanted a solution that could work with minimum customization and help them revamp their old website  -a custom-built combination of multiple tools and technologies- to achieve content and commerce autonomy for their eCommerce team.</p>
                        </div>
                    </div>
                </div>

                <div className="cta-banner case-study-top-cta mb-208">
                    <div className="container-small">
                        <div className="cta-banner-inner">
                            <div className="custom-row-big d-flex row-2 align-v-center">
                                <div className="grid">
                                    <div className="image-block">
                                        <img loading="lazy" src={CaseStudyCtaImg} title="CTA Banner Image" alt="CTA Banner Image" />
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-block"> 
                                        <h5 className="mb-32">Are you looking for the most innovative CMS to achieve autonomy for your design and marketing teams?</h5>
                                        <Link className="button black-button button-small" to="/contact/">Talk to a CMS experts</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="full-content-section mb-208">
                    <div className="container-small">
                        <div className="full-content-inner">
                            <h2 className="mb-24">The Solution</h2>
                            <p>With the knowledge that using as much technology out-of-the-box as possible would avoid heavy customization, and make the solution future proof, the Client settled on Experro. 
                            With these critical criteria in mind, the Client chose a combination of the Experro digital products to for the Digital Experience Platform inititative.</p>
                            
                            <p className="font-bold font-dark-color mb-24">Digital Products</p>
                            <ul>
                                <li className="d-flex align-v-center mb-16">
                                    <div className="icon-block headless-cms">
                                        <ContentModelIcon />
                                    </div>
                                    <p className="font-bold font-dark-color">Headless CMS</p>
                                </li>
                                <li className="d-flex align-v-center mb-16">
                                    <div className="icon-block product-discovery">
                                        <SearchcontentIcon />
                                    </div>
                                    <p className="font-bold font-dark-color">Product Discovery</p>
                                </li>
                                <li className="d-flex align-v-center mb-16">
                                    <div className="icon-block journeys">
                                        <JourneyIcon />
                                    </div>
                                    <p className="font-bold font-dark-color">Journeys</p>
                                </li>
                            </ul>
                            
                            <p>The solution enabled the Client to transform all customer-facing channels to offer seamless and hyper-personalized shopping experiences leading to more impressions and highly engaged online-shopping sessions. </p>
                            <p>The Journey product improved sales and marketing automation for the online and in-store shopping experiences. The Client can personalize all email, text, support communications, and shopping experiences using highly accurate customer segments. </p>
                            <p>With AI-powered Product Discovery and CMS capabilities, the eCommerce teams can craft desired content and search experiences for each customer. </p>
                        </div>
                    </div>
                </div>



                <div className="full-content-section mb-208">
                    <div className="container-small">
                        <div className="full-content-inner">
                            <h2 className="mb-24">The Results</h2>
                            <p>The results of the innovative DXP solution were staggering. The Client is successfully delivering a connected customer experience with improved clienteling for online and offline shoppers.</p>
                            <p>The Experro CMS helped build next-gen B2C web commerce with web designs optimized to meet DD customers' luxury and premium expectations.</p>
                            <p>With the Experro product suite catering to the needs of flexible CDP, CRM, and CMS, the Client achieved the aim of a future-proof solution customized to their needs.</p>
                            <p>The personalization of customer interactions is driving revenue and customer success by triple-digit percent.</p>

                            <div className="content-point-box">
                                <ul class="highlighted-title-text custom-row-big row-2 d-flex flex-wrap">
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <LeaderIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">25%</h3> Increase in in-store sales<br></br> conversion rate.
                                        </div> 
                                    </li>
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <ShoppingIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">16%</h3>Increase in Average<br></br> Session Duration.
                                        </div> 
                                    </li>
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <ProtectionIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">39%</h3>Increase in total online<br></br> shopping sessions.
                                        </div> 
                                    </li>
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <CodingIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">24%</h3>Reduction in customer <br></br>bounce rate.
                                        </div> 
                                    </li>
                                </ul>
                            </div>
                        
                        </div>
                    </div>
                </div>

            
                <div className="cta-banner case-study-cta-banner mb-208">
                    <div className="container-medium">
                        <div className="cta-banner-inner">
                            <div className="custom-row-big d-flex row-2 align-v-center"> 
                                <div className="grid">
                                    <div className="text-block">
                                        <h4>To learn more about the Experro</h4>
                                        <p className="mb-40">We’re helping B2B, B2C, D2C, and retailers to reinvent their digital experiences for a customer-first future.</p>
                                        <Link className="button black-button" to="/contact/">Contact Us</Link>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="image-block">
                                        <img src={CaseStudyCta} title="CTA Banner Image" alt="CTA Banner Image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="experro-more-details-section mb-208">
                    <div className="container-medium">
                        <h3 className="mb-32 text-center font-medium">More Customer Success Stories</h3>
                        <div className="icon-with-text-block icon-with-text-border-box">
                            <div className="custom-row d-flex flex-wrap row-2">
                                <div className="grid">
                                    <div className="item-inner"> 
                                        <div className="text-block">
                                            <h6>Unleashing creative potential with headless CMS</h6>
                                            <p className="small-text">How we eliminated developer dependency and providedcomplete autonomy for creating powerful content experiences?</p>
                                            <Link className="btn-link right-arrow" to="/case-study-two/">Read Story</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner"> 
                                        <div className="text-block">
                                            <h6>Connecting shoppers and Product with AI Discovery</h6>
                                            <p className="small-text">How we helped our B2B client to increase search revenue by helping shoppers find the exact SKU within seconds?</p>
                                            <Link className="btn-link right-arrow" to="/case-study-three/">Read Story</Link>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>

                
            </Layout>
        </div>
        
    );
}

export default CaseStudyPage;